export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">Mais informações</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Atualizado</p>
            <p className="additional__description">10.04.24</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">Estabelecido</p>
            <p className="additional__description">77421</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Idade</p>
            <p className="additional__description">21+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Tamanho</p>
            <p className="additional__description">4.7MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Versão atual</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Desenvolvedor</p>
            <p className="additional__description">Cassino</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Atualizado</p>
            <p className="additional__description">10.04.24</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Tamanho</p>
            <p className="additional__description">4.7MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Estabelecido</p>
            <p className="additional__description">77421</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Versão atual</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Idade</p>
            <p className="additional__description">21+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Desenvolvedor</p>
            <p className="additional__description">Cassino</p>
          </div>
        </div>
      </div>
    </div>
  );
};
